import React from "react";
import {
  AppBar,
  Button,
  Container,
  Divider,
  Link,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import Logo from "./logo.png";

function Section({
  title,
  headline,
  children,
}: {
  title: string;
  headline: string;
  children: React.ReactNode;
}) {
  return (
    <>
      <Typography
        sx={{
          fontFamily:
            '"Inter Tight", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
          fontWeight: 500,
          textTransform: "uppercase",
        }}
        variant="caption"
      >
        {title}
      </Typography>
      <Typography
        variant="h4"
        component="h3"
        gutterBottom
        sx={{
          fontFamily:
            '"Manrope", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
          fontWeight: 800,
        }}
      >
        {headline}
      </Typography>
      {children}
    </>
  );
}

function App() {
  return (
    <>
      <AppBar position="static">
        <Toolbar
          sx={{
            mt: "2px",
          }}
        >
          <Typography variant="h6" component="div">
            <img src={Logo} alt="Logo" width="128px" height="auto" />
          </Typography>
          <Stack
            sx={{
              ml: "24px",
            }}
            direction="row"
            spacing={0.5}
          >
            <Button
              variant="text"
              component={Link}
              href="https://linkedin.com/company/gama-labs"
              target="_blank"
            >
              Careers
            </Button>
            <Button
              variant="text"
              component={Link}
              href="https://twitter.com/gamalabsxyz"
              target="_blank"
            >
              Twitter
            </Button>
          </Stack>
        </Toolbar>
      </AppBar>
      <br />
      <br />
      <Container maxWidth="lg">
        <Section
          title="About"
          headline="Advanced capabilities and innovative ideas"
        >
          <Typography>
            We are pioneering the use of artificial intelligence and blockchain
            technologies to revolutionize the everyday lives of people around
            the world! By making these groundbreaking technologies accessible
            and user-friendly, we are bringing the future of technology to the
            masses.
            <br />
            <br />
            We are looking for talented people to join us in building the
            future! If you have experience in software development, artificial
            intelligence, blockchain technologies, or any related field, then we
            want to hear from you.
          </Typography>
        </Section>
      </Container>
      <br />
      <br />
      <br />
      <br />
      <Divider />
      <br />
      <br />
      <Container maxWidth="lg">
        <Section title="Search" headline="Future of search is here">
          <Typography>
            <Link href="https://algea.xyz" target="_blank">
              Algea Search
            </Link>{" "}
            is a next-generation search engine that harnesses the power of
            GPT-3, creating a profoundly advanced AI-powered search engine
            capable of accurately understanding user queries and creating
            personalised search results for every user. Not only that, but it
            can curate search results in real-time, taking into account any
            updates to the web and an unprecedented amount of data from across
            the web.
            <br />
            <br />
            The future of search is here with Algea Search, revolutionizing the
            way we search for information and creating an entirely new level of
            personalisation and accuracy in search engine results.
          </Typography>
        </Section>
      </Container>
      <br />
      <br />
      <br />
      <Divider />
      <br />
      <br />
      <Container maxWidth="lg">
        <Section title="Classify" headline="Difference, in real time">
          <Typography>
            Classify makes finding the right tool for your answers seamless!
            With natural language processing and machine learning, Classify can
            quickly and accurately distinguish the difference between queries
            and redirect you to the most appropriate tool in real time.
          </Typography>
        </Section>
      </Container>
      <br />
      <br />
      <br />
      <br />
    </>
  );
}

export default App;
