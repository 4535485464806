import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { ThemeProvider, CssBaseline, createTheme } from "@mui/material";
import "./index.css";

const theme = createTheme({
  palette: {
    background: {
      default: "#FFFDF6",
    },
  },
  typography: {
    fontFamily: [
      "Georgia",
      "Cambria",
      '"Times New Roman"',
      "Times",
      "serif",
    ].join(","),
    body1: {
      fontSize: "1.25rem",
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "#4E3E18",
          boxShadow: "none",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          maxWidth: "800px",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          color: "white",
          fontFamily:
            '"Inter Tight", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: "#4E3E18",
          textDecorationColor: "#4E3E18",
          "&:hover": {
            textDecoration: "none",
          },
        }
      }
    }
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </React.StrictMode>
);
